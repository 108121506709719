import HOSTNAMES from "shared/constants/hostnames";
import Application from "shared/types/application";
import Environment from "shared/types/environment";
import getEnvironment from "shared/utils/getEnvironment";

const getOrigin = (
  application: Application,
  environment = getEnvironment(),
) => {
  const protocol = environment === Environment.Development ? "http" : "https";

  return `${protocol}://${HOSTNAMES[application][environment]}`;
};

export default getOrigin;
