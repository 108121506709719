import GlobalNav from "internal/system/components/GlobalNav";
import Session from "internal/web/services/Session";
import { useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import CheckSession from "shared/components/CheckSession";
import FetchViewer from "shared/components/FetchViewer";
import Auth from "shared/services/InternalAuth";

interface IGuardedRouteProps extends RouteProps {
  children?: React.ReactNode;
  fullscreen?: boolean;
}

function GuardedRoute({
  children,
  exact,
  fullscreen,
  location,
  path,
}: IGuardedRouteProps) {
  const isUserAuthenticated = Session.isAuthenticated();

  const [canRenderChildren, setCanRenderChildren] = useState(false);

  return (
    <Route exact={exact} path={path}>
      {!isUserAuthenticated ? (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              referrerUri: location && location.pathname + location.search,
            },
          }}
        />
      ) : (
        <>
          {!fullscreen && <GlobalNav />}
          <CheckSession
            auth={Auth}
            onRefreshToken={() => setCanRenderChildren(true)}
            session={Session}
          />
          {isUserAuthenticated && canRenderChildren && (
            <FetchViewer>{children}</FetchViewer>
          )}
        </>
      )}
    </Route>
  );
}

export default GuardedRoute;
