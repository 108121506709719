export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  Time: { input: any; output: any };
};

export type ApprovalNoteInput = {
  id: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  showreelID: Scalars["ID"]["input"];
  token: Scalars["String"]["input"];
};

export type Asset = {
  __typename: "Asset";
  assetIndex: Scalars["Int"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  transcodeStatus: TranscodeStatus;
  transcoded: Scalars["Boolean"]["output"];
  type: MediaType;
  videoURI: Scalars["String"]["output"];
  videoURIHDR: Maybe<Scalars["String"]["output"]>;
};

export enum AssetType {
  Sequence = "SEQUENCE",
  Single = "SINGLE",
}

export enum Audience {
  Client = "CLIENT",
  Staff = "STAFF",
}

export type Brand = {
  __typename: "Brand";
  /** @deprecated no longer used on FE */
  activeBriefs: Maybe<Array<Brief>>;
  /** @deprecated no longer used on FE */
  completedBriefsConnection: BriefsConnection;
  contacts: Maybe<Array<Maybe<Contact>>>;
  id: Scalars["ID"]["output"];
  imageURI: Scalars["String"]["output"];
  ksuid: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type BrandCompletedBriefsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BrandInput = {
  avatarGUID?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Brands = {
  __typename: "Brands";
  edges: Maybe<Array<BrandsEdge>>;
  pageInfo: PageInfo;
};

export type BrandsEdge = {
  __typename: "BrandsEdge";
  cursor: Scalars["String"]["output"];
  node: Brand;
};

export type BrandsSearch = {
  __typename: "BrandsSearch";
  edges: Maybe<Array<BrandsEdge>>;
  pageInfo: PageInfo;
};

export type Brief = {
  __typename: "Brief";
  aeProjectFileGuide: Maybe<Scalars["Boolean"]["output"]>;
  aspectRatioAdUnits: Maybe<Array<Maybe<BriefAspectRatioAdUnit>>>;
  assets: Maybe<Scalars["String"]["output"]>;
  assetsLink: Maybe<Scalars["String"]["output"]>;
  audio: Maybe<Scalars["String"]["output"]>;
  beforeYouStart: Maybe<Scalars["String"]["output"]>;
  brand: Brand;
  briefCreatorsDeliverables: Maybe<Scalars["String"]["output"]>;
  briefFeaturing: Maybe<Scalars["String"]["output"]>;
  briefLocation: Maybe<Scalars["String"]["output"]>;
  briefPropsOrItems: Maybe<Scalars["String"]["output"]>;
  briefTone: Maybe<Scalars["String"]["output"]>;
  clientBriefName: Maybe<Scalars["String"]["output"]>;
  clientBriefSummary: Maybe<Scalars["String"]["output"]>;
  clientSelectionCriteria: Maybe<Scalars["String"]["output"]>;
  closed: Scalars["Boolean"]["output"];
  complete: Maybe<Scalars["Boolean"]["output"]>;
  completedAt: Maybe<Scalars["Time"]["output"]>;
  contacts: Maybe<Array<Maybe<Contact>>>;
  contentGroups: Array<Maybe<ContentGroup>>;
  createdAt: Scalars["Time"]["output"];
  creativeConcepts: Array<Maybe<CreativeConcept>>;
  creatorSelectionDate: Maybe<Scalars["Time"]["output"]>;
  creators: Maybe<Array<Maybe<CreatorBrief>>>;
  deliveryLink: Maybe<Scalars["String"]["output"]>;
  downloadURL: Maybe<Scalars["String"]["output"]>;
  filmingBestPractices: Maybe<Scalars["String"]["output"]>;
  findingPeople: Maybe<Scalars["Boolean"]["output"]>;
  genericBlocks: Maybe<Array<Maybe<BriefGenericBlock>>>;
  /** @deprecated no longer used on FE */
  hideBrand: Maybe<HideBrand>;
  id: Scalars["ID"]["output"];
  imageURI: Scalars["String"]["output"];
  introduction: Maybe<Scalars["String"]["output"]>;
  jobReference: Scalars["String"]["output"];
  languages: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  liveActionSurveyLink: Maybe<Scalars["String"]["output"]>;
  message: Maybe<Scalars["String"]["output"]>;
  numberOfConcepts: Maybe<Scalars["Int"]["output"]>;
  numberOfCreators: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated no longer used on FE */
  numberOfVideosBeingLicensed: Maybe<Scalars["Int"]["output"]>;
  performerReleases: Maybe<Scalars["Boolean"]["output"]>;
  pin: Maybe<Scalars["String"]["output"]>;
  previewHash: Maybe<Scalars["String"]["output"]>;
  productionType: BriefProductionType;
  rankedCreatorUsers: Maybe<Array<Maybe<RankedCreatorUsers>>>;
  releaseForms: Maybe<Scalars["String"]["output"]>;
  releaseFormsReturnURI: Maybe<Scalars["String"]["output"]>;
  shortHash: Scalars["String"]["output"];
  software: Maybe<Scalars["String"]["output"]>;
  staticRenderPath: Maybe<BriefStaticRenderPath>;
  status: Maybe<BriefStatus>;
  submissionDate: Maybe<Scalars["Time"]["output"]>;
  summary: Maybe<Scalars["String"]["output"]>;
  teaser: Maybe<Scalars["String"]["output"]>;
  teaserCreatorsDeliverables: Maybe<Scalars["String"]["output"]>;
  teaserFeaturing: Maybe<Scalars["String"]["output"]>;
  teaserLocation: Maybe<Scalars["String"]["output"]>;
  teaserPropsOrItems: Maybe<Scalars["String"]["output"]>;
  teaserTone: Maybe<Scalars["String"]["output"]>;
  timePaymentPerCreator: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  type: Maybe<BriefType>;
  updatedAt: Maybe<Scalars["Time"]["output"]>;
  use: Maybe<Scalars["String"]["output"]>;
  users: Maybe<Array<Maybe<User>>>;
  version: BriefVersion;
  videoBlocks: Maybe<Array<Maybe<BriefVideoBlock>>>;
  videos: Maybe<Array<Video>>;
  videosPerCreator: Maybe<Scalars["Int"]["output"]>;
};

export type BriefCreatorsArgs = {
  state?: InputMaybe<Array<CreatorBriefState>>;
};

export type BriefAspectRatioAdUnit = {
  __typename: "BriefAspectRatioAdUnit";
  adUnit: Scalars["String"]["output"];
  aspectRatio: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type BriefAspectRatioAdUnitInput = {
  adUnit: Scalars["String"]["input"];
  aspectRatio: Scalars["String"]["input"];
};

export type BriefContentGroupInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type BriefDateFilterInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  dateType?: InputMaybe<BriefDateType>;
};

export enum BriefDateType {
  Completed = "completed",
  Created = "created",
  Updated = "updated",
}

export type BriefGenericBlock = {
  __typename: "BriefGenericBlock";
  section: Scalars["String"]["output"];
  title: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type BriefGenericBlockInput = {
  ID?: InputMaybe<Scalars["ID"]["input"]>;
  section: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type BriefImageUploadUriInput = {
  ID: Scalars["ID"]["input"];
  imageFileType: ImageFileType;
};

export type BriefInput = {
  aeProjectFileGuide?: InputMaybe<Scalars["Boolean"]["input"]>;
  aspectRatioAdUnits?: InputMaybe<
    Array<InputMaybe<BriefAspectRatioAdUnitInput>>
  >;
  assets?: InputMaybe<Scalars["String"]["input"]>;
  assetsLink?: InputMaybe<Scalars["String"]["input"]>;
  audio?: InputMaybe<Scalars["String"]["input"]>;
  beforeYouStart?: InputMaybe<Scalars["String"]["input"]>;
  brandID?: InputMaybe<Scalars["ID"]["input"]>;
  briefCreatorsDeliverables?: InputMaybe<Scalars["String"]["input"]>;
  briefFeaturing?: InputMaybe<Scalars["String"]["input"]>;
  briefGenericBlocks?: InputMaybe<Array<InputMaybe<BriefGenericBlockInput>>>;
  briefLocation?: InputMaybe<Scalars["String"]["input"]>;
  briefPropsOrItems?: InputMaybe<Scalars["String"]["input"]>;
  briefTone?: InputMaybe<Scalars["String"]["input"]>;
  briefVideoBlocks?: InputMaybe<Array<InputMaybe<BriefVideoBlockInput>>>;
  clientBriefName?: InputMaybe<Scalars["String"]["input"]>;
  clientBriefSummary?: InputMaybe<Scalars["String"]["input"]>;
  clientSelectionCriteria?: InputMaybe<Scalars["String"]["input"]>;
  complete?: InputMaybe<Scalars["Boolean"]["input"]>;
  contactIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  contentGroups?: InputMaybe<Array<InputMaybe<BriefContentGroupInput>>>;
  creativeConcepts?: InputMaybe<Array<InputMaybe<CreativeConceptInput>>>;
  creatorSelectionDate?: InputMaybe<Scalars["Time"]["input"]>;
  deliveryLink?: InputMaybe<Scalars["String"]["input"]>;
  downloadURL?: InputMaybe<Scalars["String"]["input"]>;
  filmingBestPractices?: InputMaybe<Scalars["String"]["input"]>;
  findingPeople?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBrand?: InputMaybe<HideBrand>;
  imageHash?: InputMaybe<Scalars["String"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  jobReference?: InputMaybe<Scalars["String"]["input"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  liveActionSurveyLink?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  numberOfConcepts?: InputMaybe<Scalars["Int"]["input"]>;
  numberOfCreators?: InputMaybe<Scalars["Int"]["input"]>;
  numberOfVideosBeingLicensed?: InputMaybe<Scalars["Int"]["input"]>;
  performerReleases?: InputMaybe<Scalars["Boolean"]["input"]>;
  productionType?: InputMaybe<BriefProductionType>;
  releaseForms?: InputMaybe<Scalars["String"]["input"]>;
  releaseFormsReturnURI?: InputMaybe<Scalars["String"]["input"]>;
  software?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<BriefStatus>;
  submissionDate?: InputMaybe<Scalars["Time"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  teaser?: InputMaybe<Scalars["String"]["input"]>;
  teaserCreatorsDeliverables?: InputMaybe<Scalars["String"]["input"]>;
  teaserFeaturing?: InputMaybe<Scalars["String"]["input"]>;
  teaserLocation?: InputMaybe<Scalars["String"]["input"]>;
  teaserPropsOrItems?: InputMaybe<Scalars["String"]["input"]>;
  teaserTone?: InputMaybe<Scalars["String"]["input"]>;
  timePaymentPerCreator?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<BriefType>;
  use?: InputMaybe<Scalars["String"]["input"]>;
  userIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  videosPerCreator?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BriefPayment = {
  __typename: "BriefPayment";
  base: Scalars["Int"]["output"];
  bonus: Scalars["Int"]["output"];
  expense: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum BriefProductionType {
  Creator = "creator",
  InHouse = "in_house",
}

export enum BriefQuality {
  Bad = "BAD",
  Good = "GOOD",
  Great = "GREAT",
}

export type BriefQualityRatio = {
  __typename: "BriefQualityRatio";
  quality: BriefQuality;
  value: Scalars["Decimal"]["output"];
};

export type BriefQualityReport = {
  __typename: "BriefQualityReport";
  briefQualityRatios: Array<BriefQualityRatio>;
};

export type BriefState = {
  __typename: "BriefState";
  briefID: Scalars["String"]["output"];
  state: CreatorBriefState;
};

export type BriefStaticRenderPath = {
  __typename: "BriefStaticRenderPath";
  uri: Scalars["String"]["output"];
  version: Scalars["Int"]["output"];
};

export enum BriefStatus {
  Cancelled = "cancelled",
  Completed = "completed",
  CreatorsWorking = "creators_working",
  Draft = "draft",
  PostProduction = "post_production",
  Published = "published",
  TeaserPublished = "teaser_published",
}

export type BriefSubmission = {
  __typename: "BriefSubmission";
  brief: Maybe<Brief>;
  creatorBriefState: Maybe<CreatorBriefState>;
  submittedAtDate: Maybe<Scalars["Time"]["output"]>;
};

export type BriefSubmissionsEdge = {
  __typename: "BriefSubmissionsEdge";
  cursor: Scalars["String"]["output"];
  node: BriefSubmission;
};

export enum BriefType {
  LiveAction = "live_action",
  MotionGraphics = "motion_graphics",
}

export enum BriefVersion {
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
}

export type BriefVideoBlock = {
  __typename: "BriefVideoBlock";
  adUnit: Maybe<Scalars["String"]["output"]>;
  contentGroupID: Maybe<Scalars["ID"]["output"]>;
  creativeConceptID: Maybe<Scalars["ID"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  length: Maybe<Scalars["String"]["output"]>;
  lengthPrefix: Maybe<BriefVideoBlockLengthPrefix>;
  ratio: Maybe<Scalars["String"]["output"]>;
  summary: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type BriefVideoBlockInput = {
  ID?: InputMaybe<Scalars["ID"]["input"]>;
  adUnit?: InputMaybe<Scalars["String"]["input"]>;
  contentGroupID?: InputMaybe<Scalars["ID"]["input"]>;
  creativeConceptID?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  length?: InputMaybe<Scalars["String"]["input"]>;
  lengthPrefix?: InputMaybe<BriefVideoBlockLengthPrefix>;
  ratio?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export enum BriefVideoBlockLengthPrefix {
  Exact = "exact",
  Image = "image",
  UpTo = "up_to",
}

export type Briefs = {
  __typename: "Briefs";
  edges: Maybe<Array<Maybe<BriefsEdge>>>;
  pageInfo: Maybe<PageInfo>;
};

export type BriefsConnection = {
  __typename: "BriefsConnection";
  edges: Maybe<Array<BriefsEdge>>;
  pageInfo: PageInfo;
};

export type BriefsEdge = {
  __typename: "BriefsEdge";
  cursor: Scalars["String"]["output"];
  node: Brief;
};

export type BriefsForCreatorResponse = {
  __typename: "BriefsForCreatorResponse";
  edges: Maybe<Array<BriefSubmissionsEdge>>;
  pageInfo: PageInfo;
};

export type BriefsSearch = {
  __typename: "BriefsSearch";
  edges: Maybe<Array<BriefsEdge>>;
  pageInfo: PageInfo;
};

export enum BriefsSortType {
  Brand = "brand",
  Date = "date",
  Status = "status",
}

export enum Category {
  AllStar = "all_star",
  Champion = "champion",
  Contender = "contender",
  NotGoodEnough = "not_good_enough",
  Rookie = "rookie",
}

export type CloseBriefInput = {
  id: Scalars["ID"]["input"];
};

export type Comment = {
  __typename: "Comment";
  audience: Audience;
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  owner: User;
  ownerID: Scalars["ID"]["output"];
  updatedAt: Scalars["Time"]["output"];
};

export type Contact = {
  __typename: "Contact";
  displayName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jobTitle: Scalars["String"]["output"];
};

export type ContactInput = {
  brandID: Scalars["ID"]["input"];
  displayName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  jobTitle: Scalars["String"]["input"];
};

export type ContentGroup = {
  __typename: "ContentGroup";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /** @deprecated no longer used on FE */
  requiredVideos: Maybe<Scalars["Int"]["output"]>;
  videos: Maybe<Array<Video>>;
};

export type CreateBriefCreatorSelectionCsvInput = {
  id: Scalars["ID"]["input"];
};

export type CreateFeedbackCommentInput = {
  hash: Scalars["ID"]["input"];
  parentID?: InputMaybe<Scalars["ID"]["input"]>;
  showreelID: Scalars["Int"]["input"];
  text?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
  videoID: Scalars["Int"]["input"];
};

export type CreateShowreelInput = {
  bannerDate?: InputMaybe<Scalars["String"]["input"]>;
  bannerNumberOfVideos?: InputMaybe<Scalars["Int"]["input"]>;
  briefID: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  nameType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ShowreelType>;
  videos: Array<ShowreelVideoInput>;
};

export type CreateTaskInput = {
  projectID: Scalars["ID"]["input"];
  taskType: TaskType;
  videoIDs: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type CreateVideoBatchDownloadInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  videoIDs: Array<Scalars["ID"]["input"]>;
};

export type CreativeConcept = {
  __typename: "CreativeConcept";
  default: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  requiredVideos: Scalars["Int"]["output"];
  videos: Maybe<Array<Video>>;
};

export type CreativeConceptInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  requiredVideos: Scalars["Int"]["input"];
};

export type CreativeConceptsInput = {
  creativeConcepts: Array<InputMaybe<CreativeConceptInput>>;
  projectID: Scalars["ID"]["input"];
};

export type Creator = {
  __typename: "Creator";
  auth0ID: Scalars["String"]["output"];
  avatarURI: Scalars["String"]["output"];
  banned: Scalars["Boolean"]["output"];
  brands: Maybe<Array<Brand>>;
  briefs: Maybe<Array<Brief>>;
  briefsCompleted: Scalars["Int"]["output"];
  busy: Scalars["Boolean"]["output"];
  categories: Maybe<CreatorCategories>;
  currency: CreatorCurrency;
  email: Scalars["String"]["output"];
  externalID: Scalars["ID"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  ksuid: Maybe<Scalars["ID"]["output"]>;
  location: Maybe<Scalars["String"]["output"]>;
  paymentSummary: Maybe<PaymentSummary>;
  portfolio: Maybe<Scalars["String"]["output"]>;
  portfolioRatings: Maybe<CreatorPortfolioRatings>;
  skills: CreatorSkills;
  videos: CreatorVideos;
};

export type CreatorBrandsArgs = {
  briefType?: InputMaybe<BriefType>;
};

export type CreatorBriefsArgs = {
  state?: InputMaybe<Array<CreatorBriefState>>;
};

export type CreatorVideosArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  brandID?: InputMaybe<Scalars["ID"]["input"]>;
  briefType?: InputMaybe<BriefType>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreatorBannedInput = {
  banned: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type CreatorBrief = {
  __typename: "CreatorBrief";
  creator: Creator;
  state: CreatorBriefState;
  updatedAt: Scalars["Time"]["output"];
};

export enum CreatorBriefLifecycleState {
  Active = "active",
  Previous = "previous",
  SelectionPending = "selection_pending",
}

export enum CreatorBriefState {
  Active = "active",
  ApplicationWithdrawn = "application_withdrawn",
  Backup = "backup",
  Completed = "completed",
  Declined = "declined",
  Incomplete = "incomplete",
  InviteDeclined = "invite_declined",
  InviteWithdrawn = "invite_withdrawn",
  Invited = "invited",
  Pending = "pending",
  Selected = "selected",
}

export type CreatorBriefStateInput = {
  briefID: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  ignoreBusyState?: InputMaybe<Scalars["Boolean"]["input"]>;
  state: CreatorBriefState;
};

export type CreatorCategories = {
  __typename: "CreatorCategories";
  cgc: Category;
  remix: Category;
};

export type CreatorCurrency = {
  __typename: "CreatorCurrency";
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  symbol: Array<Maybe<Scalars["Int"]["output"]>>;
};

export type CreatorPayment = {
  __typename: "CreatorPayment";
  base: Scalars["Int"]["output"];
  bonus: Scalars["Int"]["output"];
  brand: Brand;
  brief: PaymentBrief;
  expense: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  state: PaymentState;
  timePaymentPerCreator: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type CreatorPaymentAllocation = {
  __typename: "CreatorPaymentAllocation";
  creatorName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  informed: Scalars["Boolean"]["output"];
  notes: Maybe<Scalars["String"]["output"]>;
  payments: BriefPayment;
  selected: Scalars["Int"]["output"];
  shortlisted: Scalars["Int"]["output"];
  submitted: Scalars["Int"]["output"];
  supportingFilesStatus: SupportingFilesStatus;
};

export type CreatorPaymentAllocationInput = {
  basePayment?: InputMaybe<Scalars["Int"]["input"]>;
  bonusPayment?: InputMaybe<Scalars["Int"]["input"]>;
  expensePayment?: InputMaybe<Scalars["Int"]["input"]>;
  files?: InputMaybe<SupportingFilesStatus>;
  id: Scalars["ID"]["input"];
  informed?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatorPaymentAllocations = {
  __typename: "CreatorPaymentAllocations";
  allocations: Maybe<Array<Maybe<CreatorPaymentAllocation>>>;
  awaitingDetailsDate: Maybe<Scalars["Time"]["output"]>;
  /** @deprecated no longer used on FE */
  csvURI: Maybe<Scalars["String"]["output"]>;
  initialBasePayment: Scalars["Int"]["output"];
  /** @deprecated no longer used on FE */
  locked: Scalars["Boolean"]["output"];
  maxPayments: Maybe<BriefPayment>;
  paymentDate: Maybe<Scalars["Time"]["output"]>;
  requiredSubmissions: Scalars["Int"]["output"];
  state: PaymentBriefState;
  totals: BriefPayment;
  verifiedDate: Maybe<Scalars["Time"]["output"]>;
};

export type CreatorPaymentAllocationsInput = {
  allocations?: InputMaybe<Array<InputMaybe<CreatorPaymentAllocationInput>>>;
  state?: InputMaybe<PaymentBriefState>;
};

export type CreatorPayments = PaginatedList & {
  __typename: "CreatorPayments";
  edges: Maybe<Array<PaymentEdge>>;
  pageInfo: PageInfo;
};

export type CreatorPortfolioRatings = {
  __typename: "CreatorPortfolioRatings";
  cgc: Maybe<PortfolioRating>;
  remix: Maybe<PortfolioRating>;
};

export type CreatorPortfolioSkillRatingInput = {
  externalCreatorID: Scalars["ID"]["input"];
  rating: PortfolioRating;
  skill: CreatorSkill;
};

export enum CreatorSkill {
  Cgc = "cgc",
  Remix = "remix",
}

export type CreatorSkills = {
  __typename: "CreatorSkills";
  cgc: Scalars["Boolean"]["output"];
  remix: Scalars["Boolean"]["output"];
};

export type CreatorVideo = {
  __typename: "CreatorVideo";
  /** @deprecated no longer used on FE */
  createdAt: Scalars["Time"]["output"];
  gifURI: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  rating: Maybe<VideoRating>;
  /** @deprecated no longer used on FE */
  shortHash: Scalars["String"]["output"];
  thumbnailURI: Scalars["String"]["output"];
  variant: Maybe<Variant>;
  /** @deprecated no longer used on FE */
  videoURI: Scalars["String"]["output"];
};

export type CreatorVideos = {
  __typename: "CreatorVideos";
  edges: Maybe<Array<CreatorVideosEdge>>;
  pageInfo: PageInfo;
};

export type CreatorVideosEdge = {
  __typename: "CreatorVideosEdge";
  cursor: Scalars["String"]["output"];
  node: CreatorVideo;
};

export type Creators = {
  __typename: "Creators";
  edges: Maybe<Array<CreatorsEdge>>;
  pageInfo: PageInfo;
};

export type CreatorsBusyStatus = {
  __typename: "CreatorsBusyStatus";
  briefStates: Array<Maybe<BriefState>>;
  creatorID: Scalars["String"]["output"];
};

export type CreatorsEdge = {
  __typename: "CreatorsEdge";
  cursor: Scalars["String"]["output"];
  node: Creator;
};

export type CreatorsSearch = {
  __typename: "CreatorsSearch";
  edges: Maybe<Array<CreatorsEdge>>;
  pageInfo: PageInfo;
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars["Time"]["input"]>;
  to?: InputMaybe<Scalars["Time"]["input"]>;
};

export type Feedback = {
  __typename: "Feedback";
  assetIndex: Scalars["Int"]["output"];
  audience: Audience;
  comments: Array<Comment>;
  completed: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  owner: User;
  ownerID: Scalars["ID"]["output"];
  taskID: Scalars["ID"]["output"];
  timecode: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["Time"]["output"];
};

export type FeedbackComment = {
  __typename: "FeedbackComment";
  author: Maybe<Scalars["ID"]["output"]>;
  createdAt: Maybe<Scalars["Time"]["output"]>;
  done: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  parentID: Maybe<Scalars["ID"]["output"]>;
  showreel: Showreel;
  showreelID: Scalars["ID"]["output"];
  text: Scalars["String"]["output"];
  timestamp: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackCommentInput = {
  done?: InputMaybe<Scalars["Boolean"]["input"]>;
  hash: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  showreelID: Scalars["Int"]["input"];
  text?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
  videoID: Scalars["Int"]["input"];
};

export type FeedbackFilter = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum HideBrand {
  Both = "both",
  None = "none",
  Teaser = "teaser",
}

export enum ImageFileType {
  Jpg = "jpg",
  Png = "png",
}

export type ImageRequestUploadUri = {
  __typename: "ImageRequestUploadURI";
  URI: Scalars["String"]["output"];
  avatarGUID: Scalars["String"]["output"];
};

export type InspoTagInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export enum MediaType {
  Image = "image",
  Video = "video",
}

export type Mutation = {
  __typename: "Mutation";
  closeBrief: Brief;
  createApprovalNotes: Maybe<NoteVersion>;
  createBrand: Brand;
  createBrandImageUploadURI: ImageRequestUploadUri;
  createBrief: Brief;
  createBriefCreatorPaymentAllocationsCSV: Scalars["String"]["output"];
  createBriefCreatorSelectionCSV: Scalars["String"]["output"];
  /** @deprecated No longer used by FE */
  createBriefImageUploadURI: ImageRequestUploadUri;
  createComment: Comment;
  createContact: Contact;
  createFeedback: Feedback;
  createPaymentBriefCSV: Scalars["String"]["output"];
  createProjectZip: ZipStatus;
  createShowreel: Maybe<Showreel>;
  createShowreelComment: FeedbackComment;
  createShowreelFeedbackToken: Scalars["String"]["output"];
  createTask: Task;
  createVariantFromSuccessfulUpload: SuccessfulUploadVariant;
  createVariantUploadURI: VariantRequestUploadUri;
  createVideoBatchDownload: Maybe<VideoBatchDownload>;
  createVideoForVariants: Video;
  deleteComment: Scalars["Boolean"]["output"];
  deleteContact: Scalars["Boolean"]["output"];
  deleteFeedback: Scalars["Boolean"]["output"];
  deleteShowreelComment: Scalars["Boolean"]["output"];
  sendCreatorInvite: CreatorBrief;
  updateBrand: Brand;
  updateBrief: Brief;
  updateBriefCreatorPaymentAllocations: Maybe<CreatorPaymentAllocations>;
  updateBriefPin: Brief;
  updateCancelBrief: Brief;
  updateComment: Comment;
  updateContact: Contact;
  updateCreatorBriefState: CreatorBrief;
  updateCreatorPortfolioSkillRating: Maybe<Creator>;
  updateCreatorSetBanned: Maybe<Creator>;
  updateFeedback: Feedback;
  updateFeedbackCompleted: Feedback;
  updateInspoVideos: Scalars["Boolean"]["output"];
  updatePaymentBriefs: Maybe<Array<Maybe<PaymentBrief>>>;
  updateProject: Project;
  updateSelectedCreators: Scalars["Boolean"]["output"];
  updateShowreel: Maybe<Showreel>;
  updateShowreelComment: FeedbackComment;
  updateShowreelFeedback: ShowreelFeedback;
  updateTask: Task;
  updateUnratedVideos: Array<Maybe<Video>>;
  updateVariantFeedback: VariantFeedback;
  updateVariantVersionVisibility: Maybe<Variant>;
  updateVersionVisibility: Array<Maybe<Video>>;
  updateVideoAssignedCreator: Maybe<Array<Maybe<Video>>>;
  updateVideoContentGroup: Maybe<Video>;
  updateVideoCreativeConcept: Maybe<Video>;
  updateVideoNotes: Maybe<Video>;
  updateVideoRating: Maybe<Video>;
  updateVideoTags: Maybe<Video>;
  updateVideoVariant: Maybe<VideoVariant>;
  updateVideoVariantVisibility: Maybe<VideoVariant>;
  updateVideoVariantsAdUnitAndLanguage: Maybe<Video>;
  updateVideoVisibility: Maybe<Array<Maybe<Video>>>;
  updateVideosOrder: Array<Maybe<Video>>;
  updateVoidBrief: Brief;
  viewer: User;
};

export type MutationCloseBriefArgs = {
  input: CloseBriefInput;
};

export type MutationCreateApprovalNotesArgs = {
  input: ApprovalNoteInput;
};

export type MutationCreateBrandArgs = {
  input: BrandInput;
};

export type MutationCreateBrandImageUploadUriArgs = {
  imageFileType: ImageFileType;
};

export type MutationCreateBriefArgs = {
  input: BriefInput;
};

export type MutationCreateBriefCreatorPaymentAllocationsCsvArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationCreateBriefCreatorSelectionCsvArgs = {
  input: CreateBriefCreatorSelectionCsvInput;
};

export type MutationCreateBriefImageUploadUriArgs = {
  input?: InputMaybe<BriefImageUploadUriInput>;
};

export type MutationCreateCommentArgs = {
  input: NewComment;
};

export type MutationCreateContactArgs = {
  input: ContactInput;
};

export type MutationCreateFeedbackArgs = {
  input: NewFeedback;
};

export type MutationCreatePaymentBriefCsvArgs = {
  input?: InputMaybe<PaymentBriefCsvInput>;
};

export type MutationCreateProjectZipArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateShowreelArgs = {
  input?: InputMaybe<CreateShowreelInput>;
};

export type MutationCreateShowreelCommentArgs = {
  input: CreateFeedbackCommentInput;
};

export type MutationCreateShowreelFeedbackTokenArgs = {
  input: ShowreelFeedbackTokenInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateVariantFromSuccessfulUploadArgs = {
  input: VariantFromSuccessfulUploadInput;
};

export type MutationCreateVariantUploadUriArgs = {
  videoFileType: VideoFileType;
};

export type MutationCreateVideoBatchDownloadArgs = {
  input?: InputMaybe<CreateVideoBatchDownloadInput>;
};

export type MutationCreateVideoForVariantsArgs = {
  input: VideoInput;
};

export type MutationDeleteCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteContactArgs = {
  brandID?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFeedbackArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteShowreelCommentArgs = {
  input: FeedbackCommentInput;
};

export type MutationSendCreatorInviteArgs = {
  input: SendCreatorInviteInput;
};

export type MutationUpdateBrandArgs = {
  id: Scalars["ID"]["input"];
  input: BrandInput;
};

export type MutationUpdateBriefArgs = {
  id: Scalars["ID"]["input"];
  input: BriefInput;
};

export type MutationUpdateBriefCreatorPaymentAllocationsArgs = {
  id: Scalars["ID"]["input"];
  input: CreatorPaymentAllocationsInput;
};

export type MutationUpdateBriefPinArgs = {
  input: UpdateBriefPinInput;
};

export type MutationUpdateCancelBriefArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpdateCreatorBriefStateArgs = {
  input: CreatorBriefStateInput;
};

export type MutationUpdateCreatorPortfolioSkillRatingArgs = {
  input: CreatorPortfolioSkillRatingInput;
};

export type MutationUpdateCreatorSetBannedArgs = {
  input?: InputMaybe<CreatorBannedInput>;
};

export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};

export type MutationUpdateFeedbackCompletedArgs = {
  input: UpdateFeedbackCompletedInput;
};

export type MutationUpdateInspoVideosArgs = {
  input: UpdateInspoVideosInput;
};

export type MutationUpdatePaymentBriefsArgs = {
  input?: InputMaybe<PaymentBriefsInput>;
};

export type MutationUpdateProjectArgs = {
  input: ProjectInput;
};

export type MutationUpdateSelectedCreatorsArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateShowreelArgs = {
  input?: InputMaybe<UpdateShowreelInput>;
};

export type MutationUpdateShowreelCommentArgs = {
  input: FeedbackCommentInput;
};

export type MutationUpdateShowreelFeedbackArgs = {
  input: ShowreelFeedbackInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateUnratedVideosArgs = {
  input: UnratedVideosInput;
};

export type MutationUpdateVariantFeedbackArgs = {
  input: VariantFeedbackInput;
};

export type MutationUpdateVariantVersionVisibilityArgs = {
  input?: InputMaybe<VariantVersionVisibilityInput>;
};

export type MutationUpdateVersionVisibilityArgs = {
  input: UpdateVersionVisibilityInput;
};

export type MutationUpdateVideoAssignedCreatorArgs = {
  input?: InputMaybe<VideoAssignedCreatorInput>;
};

export type MutationUpdateVideoContentGroupArgs = {
  input: VideoContentGroupInput;
};

export type MutationUpdateVideoCreativeConceptArgs = {
  input: VideoCreativeConceptInput;
};

export type MutationUpdateVideoNotesArgs = {
  input: VideoNotesInput;
};

export type MutationUpdateVideoRatingArgs = {
  input: VideoRatingInput;
};

export type MutationUpdateVideoTagsArgs = {
  input: VideoTagsInput;
};

export type MutationUpdateVideoVariantArgs = {
  input: UpdateVideoVariantInput;
};

export type MutationUpdateVideoVariantVisibilityArgs = {
  input?: InputMaybe<VideoVariantVisibilityInput>;
};

export type MutationUpdateVideoVariantsAdUnitAndLanguageArgs = {
  input: VideoVariantsAdUnitAndLanguageInput;
};

export type MutationUpdateVideoVisibilityArgs = {
  input?: InputMaybe<VideoVisibilityInput>;
};

export type MutationUpdateVideosOrderArgs = {
  input: VideosOrderInput;
};

export type MutationUpdateVoidBriefArgs = {
  id: Scalars["ID"]["input"];
};

export type NewComment = {
  audience: Audience;
  feedbackID: Scalars["ID"]["input"];
  message: Scalars["String"]["input"];
};

export type NewFeedback = {
  assetIndex?: InputMaybe<Scalars["Int"]["input"]>;
  audience: Audience;
  message: Scalars["String"]["input"];
  taskID: Scalars["ID"]["input"];
  timecode?: InputMaybe<Scalars["Int"]["input"]>;
  videoVariantID: Scalars["ID"]["input"];
};

export enum NoteType {
  Approval = "approval",
}

export type NoteVersion = {
  __typename: "NoteVersion";
  /** @deprecated no longer used on FE */
  briefID: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated no longer used on FE */
  createdAt: Scalars["Time"]["output"];
  createdBy: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated no longer used on FE */
  id: Scalars["String"]["output"];
  /** @deprecated no longer used on FE */
  noteSubtype: Maybe<Scalars["String"]["output"]>;
  noteText: Scalars["String"]["output"];
  noteType: NoteType;
  /** @deprecated no longer used on FE */
  position: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated no longer used on FE */
  showreelVideoID: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated no longer used on FE */
  title: Maybe<Scalars["String"]["output"]>;
  /** @deprecated no longer used on FE */
  updatedAt: Scalars["Time"]["output"];
  /** @deprecated no longer used on FE */
  versionGUID: Scalars["String"]["output"];
  videoID: Maybe<Scalars["Int"]["output"]>;
};

export enum Office {
  London = "london",
  NewYork = "new_york",
}

export type PageInfo = {
  __typename: "PageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
};

export type PaginatedEdge = {
  cursor: Scalars["String"]["output"];
};

export type PaginatedList = {
  pageInfo: PageInfo;
};

export type PaymentBrief = {
  __typename: "PaymentBrief";
  brandName: Scalars["String"]["output"];
  briefName: Scalars["String"]["output"];
  creators: Maybe<Scalars["Int"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  expectedPaymentDate: Maybe<Scalars["Time"]["output"]>;
  id: Scalars["ID"]["output"];
  jobReference: Scalars["String"]["output"];
  owner: Maybe<Scalars["String"]["output"]>;
  payment: Maybe<BriefPayment>;
  paymentDate: Maybe<Scalars["Time"]["output"]>;
  shortHash: Scalars["String"]["output"];
  submissionDeadlineDate: Maybe<Scalars["Time"]["output"]>;
};

export type PaymentBriefCsvInput = {
  country?: InputMaybe<PaymentBriefCountry>;
  dateRange?: InputMaybe<DateRangeInput>;
  issues?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum PaymentBriefCountry {
  Uk = "uk",
  Us = "us",
}

export enum PaymentBriefState {
  AwaitingDetails = "awaiting_details",
  Complete = "complete",
  Pending = "pending",
  Verified = "verified",
}

export type PaymentBriefs = {
  __typename: "PaymentBriefs";
  edges: Maybe<Array<PaymentBriefsEdge>>;
  pageInfo: PageInfo;
};

export type PaymentBriefsEdge = {
  __typename: "PaymentBriefsEdge";
  cursor: Scalars["String"]["output"];
  node: PaymentBrief;
};

export type PaymentBriefsInput = {
  ids: Array<InputMaybe<Scalars["ID"]["input"]>>;
  locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<PaymentBriefState>;
};

export type PaymentEdge = PaginatedEdge & {
  __typename: "PaymentEdge";
  cursor: Scalars["String"]["output"];
  node: CreatorPayment;
};

export enum PaymentState {
  Paid = "PAID",
  Pending = "PENDING",
}

export type PaymentSummary = {
  __typename: "PaymentSummary";
  /** @deprecated no longer used on FE */
  briefsTotal: Scalars["Int"]["output"];
  earningsTotal: Scalars["Int"]["output"];
};

export enum PortfolioRating {
  Bad = "bad",
  Good = "good",
  Great = "great",
  MultipleAccounts = "multiple_accounts",
  NotACreator = "not_a_creator",
  NotCgc = "not_cgc",
  NotRemix = "not_remix",
  PortfolioError = "portfolio_error",
  Staff = "staff",
}

export type Project = {
  __typename: "Project";
  briefID: Scalars["ID"]["output"];
  creativeConcepts: Array<Maybe<CreativeConcept>>;
  csm: Maybe<User>;
  currentTask: Maybe<Task>;
  id: Scalars["ID"]["output"];
  office: Office;
  requiredVideos: Scalars["Int"]["output"];
  status: Maybe<ProjectStatus>;
  tasks: Array<Maybe<Task>>;
};

export type ProjectInput = {
  briefID: Scalars["ID"]["input"];
  csmID?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
  office?: InputMaybe<Office>;
  status?: InputMaybe<ProjectStatus>;
  tasks?: InputMaybe<Array<InputMaybe<TaskInput>>>;
};

export enum ProjectStatus {
  Closed = "closed",
  New = "new",
  PostProd = "postProd",
  Unknown = "unknown",
}

export type Query = {
  __typename: "Query";
  brand: Brand;
  brands: Brands;
  brandsByNameSearchTerm: BrandsSearch;
  brandsForCreator: Array<Brand>;
  brief: Brief;
  briefCreatorPaymentAllocations: CreatorPaymentAllocations;
  briefQualityReport: Maybe<BriefQualityReport>;
  briefShowreels: Showreels;
  briefs: Briefs;
  briefsForCreator: BriefsForCreatorResponse;
  briefsQualityReport: Maybe<BriefQualityReport>;
  briefsSearch: BriefsSearch;
  commentsByVideoID: Array<VariantFeedback>;
  creator: Creator;
  creatorPayments: CreatorPayments;
  creators: Creators;
  creatorsBusyStatus: Maybe<Array<CreatorsBusyStatus>>;
  creatorsByNameSearchTerm: CreatorsSearch;
  paymentBriefsByState: PaymentBriefs;
  project: Maybe<Project>;
  search: Maybe<SearchResults>;
  /** @deprecated no longer used byt FE */
  showreel: Showreel;
  showreelByGUID: Showreel;
  showreelFeedback: ShowreelFeedback;
  taxonomies: Taxonomies;
  unratedRookies: Array<Maybe<Creator>>;
  usersByOrgUnit: Array<Maybe<User>>;
  variant: Variant;
  video: Video;
  videoBatchDownload: Maybe<VideoBatchDownload>;
  /** @deprecated no longer used on FE */
  viewer: User;
  zipStatus: ZipStatus;
};

export type QueryBrandArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryBrandsByNameSearchTermArgs = {
  searchTerm: Scalars["String"]["input"];
};

export type QueryBrandsForCreatorArgs = {
  externalUserID: Scalars["ID"]["input"];
};

export type QueryBriefArgs = {
  shortHash: Scalars["ID"]["input"];
};

export type QueryBriefCreatorPaymentAllocationsArgs = {
  shortHash: Scalars["ID"]["input"];
};

export type QueryBriefQualityReportArgs = {
  briefID: Scalars["ID"]["input"];
};

export type QueryBriefShowreelsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  shortHash: Scalars["ID"]["input"];
};

export type QueryBriefsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  brandID?: InputMaybe<Scalars["ID"]["input"]>;
  dateFilter?: InputMaybe<BriefDateFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<BriefsSortType>;
  statuses?: InputMaybe<Array<InputMaybe<BriefStatus>>>;
  userID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryBriefsForCreatorArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  brandID?: InputMaybe<Scalars["ID"]["input"]>;
  externalUserID: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  lifecycleState: CreatorBriefLifecycleState;
};

export type QueryBriefsQualityReportArgs = {
  briefLeadID?: InputMaybe<Scalars["ID"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  startDate: Scalars["DateTime"]["input"];
};

export type QueryBriefsSearchArgs = {
  searchTerm: Scalars["String"]["input"];
};

export type QueryCommentsByVideoIdArgs = {
  videoID: Scalars["ID"]["input"];
};

export type QueryCreatorArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCreatorPaymentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["ID"]["input"];
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeBanned?: Scalars["Boolean"]["input"];
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCreatorsBusyStatusArgs = {
  creatorIDs: Array<Scalars["ID"]["input"]>;
};

export type QueryCreatorsByNameSearchTermArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeBanned?: Scalars["Boolean"]["input"];
  last?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm: Scalars["String"]["input"];
};

export type QueryPaymentBriefsByStateArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<PaymentBriefCountry>;
  dateRange?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  issues?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<PaymentBriefState>;
};

export type QueryProjectArgs = {
  id: Scalars["ID"]["input"];
};

export type QuerySearchArgs = {
  input: SearchInput;
};

export type QueryShowreelArgs = {
  shortHash: Scalars["ID"]["input"];
};

export type QueryShowreelByGuidArgs = {
  guid: Scalars["ID"]["input"];
};

export type QueryShowreelFeedbackArgs = {
  id: Scalars["ID"]["input"];
  includeFromPreviousShowreels?: Scalars["Boolean"]["input"];
};

export type QueryUsersByOrgUnitArgs = {
  orgUnit: UserOrgUnit;
};

export type QueryVariantArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVideoArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVideoBatchDownloadArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryZipStatusArgs = {
  id: Scalars["ID"]["input"];
};

export type RankedCreatorUsers = {
  __typename: "RankedCreatorUsers";
  category: Category;
  creators: Array<CreatorBrief>;
};

export type SearchDimensions = {
  __typename: "SearchDimensions";
  framework: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  visualTechniques: Array<Scalars["String"]["output"]>;
};

export type SearchInput = {
  brandName?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clientApproved?: InputMaybe<Array<Scalars["String"]["input"]>>;
  durationRange?: InputMaybe<Array<Scalars["String"]["input"]>>;
  industry?: InputMaybe<Array<Scalars["String"]["input"]>>;
  misc?: InputMaybe<Array<Scalars["String"]["input"]>>;
  origin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  placement?: InputMaybe<Array<Scalars["String"]["input"]>>;
  projectType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  shuffle?: InputMaybe<Scalars["Int"]["input"]>;
  videoFramework?: InputMaybe<Array<Scalars["String"]["input"]>>;
  videoRating?: InputMaybe<Array<VideoRating>>;
  videoURL?: InputMaybe<Scalars["String"]["input"]>;
  videoVisualTechniques?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type SearchPlacement = {
  __typename: "SearchPlacement";
  adUnit: Scalars["String"]["output"];
  aspectRatio: Scalars["String"]["output"];
  createdAt: Scalars["Time"]["output"];
  duration: Scalars["Int"]["output"];
  language: Scalars["String"]["output"];
  videoMP4Url: Scalars["String"]["output"];
  videoWebMUrl: Scalars["String"]["output"];
};

export type SearchResults = {
  __typename: "SearchResults";
  videos: Maybe<SearchVideos>;
};

export type SearchResultsVideosArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type SearchVideo = {
  __typename: "SearchVideo";
  brand: Brand;
  briefCompletedAt: Scalars["Time"]["output"];
  briefID: Scalars["Int"]["output"];
  briefTitle: Scalars["String"]["output"];
  createdAt: Scalars["Time"]["output"];
  creator: Scalars["String"]["output"];
  dimensions: SearchDimensions;
  id: Scalars["ID"]["output"];
  industry: Scalars["String"]["output"];
  misc: Array<Scalars["String"]["output"]>;
  origin: Scalars["String"]["output"];
  placements: Array<SearchPlacement>;
  shortHash: Scalars["String"]["output"];
  videoConcept: Scalars["String"]["output"];
  videoRating: VideoRating;
  videoShorthash: Scalars["String"]["output"];
  videoStatus: Scalars["String"]["output"];
};

export type SearchVideos = PaginatedList & {
  __typename: "SearchVideos";
  edges: Maybe<Array<SearchVideosEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SearchVideosEdge = PaginatedEdge & {
  __typename: "SearchVideosEdge";
  cursor: Scalars["String"]["output"];
  node: SearchVideo;
};

export enum SelectedStatus {
  NotSelected = "NOT_SELECTED",
  Pending = "PENDING",
  Selected = "SELECTED",
}

export type SendCreatorInviteInput = {
  briefID: Scalars["ID"]["input"];
  creatorID: Scalars["ID"]["input"];
};

export type Showreel = {
  __typename: "Showreel";
  active: Scalars["Boolean"]["output"];
  authorBrandUserID: Scalars["Int"]["output"];
  authorName: Scalars["String"]["output"];
  bannerDate: Maybe<Scalars["Time"]["output"]>;
  bannerNumberOfVideos: Maybe<Scalars["Int"]["output"]>;
  bannerType: Maybe<Scalars["String"]["output"]>;
  brand: Brand;
  brief: Brief;
  /** @deprecated no longer needed but still on FE */
  briefID: Scalars["Int"]["output"];
  /** @deprecated no longer needed but still on FE */
  briefShortHash: Scalars["String"]["output"];
  contentType: Maybe<ShowreelContentType>;
  createdAt: Scalars["Time"]["output"];
  feedbackDate: Maybe<Scalars["Time"]["output"]>;
  feedbackProvided: Scalars["Boolean"]["output"];
  guid: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  languageBlacklist: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  lastUpdatedAuthorName: Scalars["String"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  nameType: Maybe<Scalars["String"]["output"]>;
  shortHash: Scalars["ID"]["output"];
  type: ShowreelType;
  updatedAt: Scalars["Time"]["output"];
  variantBlacklist: Maybe<Array<Maybe<ShowreelVariant>>>;
  videos: Maybe<Array<Maybe<Video>>>;
  viewCount: Maybe<Scalars["Int"]["output"]>;
};

export type ShowreelVideosArgs = {
  includeVideosWithNoAllowlistedVariants?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

export enum ShowreelContentType {
  Static = "static",
  Video = "video",
}

export type ShowreelFeedback = {
  __typename: "ShowreelFeedback";
  feedback: Maybe<Array<Maybe<VariantFeedback>>>;
  outdated: Scalars["Boolean"]["output"];
  selectedVideos: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  showreelID: Scalars["ID"]["output"];
  submitted: Scalars["Boolean"]["output"];
  submittedAt: Maybe<Scalars["Time"]["output"]>;
};

export type ShowreelFeedbackInput = {
  selectedVideos?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showreelID: Scalars["ID"]["input"];
  submitted?: InputMaybe<Scalars["Boolean"]["input"]>;
  token: Scalars["String"]["input"];
};

export type ShowreelFeedbackTokenInput = {
  id: Scalars["ID"]["input"];
  pin: Scalars["String"]["input"];
};

export enum ShowreelType {
  Approval = "approval",
  FeedbackOnly = "feedback_only",
  SelectFeedback = "select_feedback",
  Summary = "summary",
  ViewOnly = "view_only",
}

export type ShowreelVariant = {
  __typename: "ShowreelVariant";
  adUnit: Scalars["String"]["output"];
  aspectRatio: Scalars["String"]["output"];
};

export type ShowreelVariantInput = {
  adUnit: Scalars["String"]["input"];
  aspectRatio: Scalars["String"]["input"];
};

export type ShowreelVideoInput = {
  notes?: InputMaybe<Scalars["String"]["input"]>;
  showreelVideoID?: InputMaybe<Scalars["ID"]["input"]>;
  videoID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Showreels = {
  __typename: "Showreels";
  edges: Maybe<Array<ShowreelsEdge>>;
  pageInfo: PageInfo;
};

export type ShowreelsEdge = {
  __typename: "ShowreelsEdge";
  cursor: Scalars["String"]["output"];
  node: Showreel;
};

export type StartTaskInput = {
  projectID: Scalars["ID"]["input"];
  videoIDs: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type SuccessfulUploadVariant = {
  __typename: "SuccessfulUploadVariant";
  variant: Variant;
  videoID: Scalars["ID"]["output"];
};

export enum SupportingFilesStatus {
  Chased = "chased",
  Empty = "empty",
  Late = "late",
  NotReceived = "not_received",
  Received = "received",
}

export type Task = {
  __typename: "Task";
  completedAt: Maybe<Scalars["Time"]["output"]>;
  dueDate: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  kind: TaskType;
  state: Maybe<TaskState>;
  title: Scalars["String"]["output"];
};

export type TaskInput = {
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  kind: TaskType;
  state?: InputMaybe<TaskState>;
};

export enum TaskState {
  Completed = "completed",
  Due = "due",
  UnknownState = "unknownState",
  Upcoming = "upcoming",
}

export enum TaskType {
  Approval = "approval",
  Curation = "curation",
  Download = "download",
  Review = "review",
  Select = "select",
  Unknown = "unknown",
}

export type Taxon = {
  __typename: "Taxon";
  count: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type Taxonomies = {
  __typename: "Taxonomies";
  language: Taxonomy;
  videos: Maybe<VideoTaxonomies>;
};

export type TaxonomiesVideosArgs = {
  input?: InputMaybe<SearchInput>;
};

export type Taxonomy = {
  __typename: "Taxonomy";
  id: Scalars["ID"]["output"];
  values: Array<Maybe<Taxon>>;
};

export enum TranscodeStatus {
  Complete = "complete",
  Error = "error",
  Scheduled = "scheduled",
  Transcoding = "transcoding",
  Unknown = "unknown",
}

export type UnratedVideosInput = {
  briefID: Scalars["ID"]["input"];
};

export type UpdateBriefPinInput = {
  briefID: Scalars["ID"]["input"];
  pin: Scalars["String"]["input"];
};

export type UpdateCommentInput = {
  id: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateContactInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFeedbackCompletedInput = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type UpdateFeedbackInput = {
  id: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  timecode?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateInspoVideosInput = {
  brandID: Scalars["Int"]["input"];
  brandIndustry: InspoTagInput;
  videoFramework: InspoTagInput;
  videoID: Scalars["Int"]["input"];
  videoVisualTechniques: Array<InspoTagInput>;
};

export type UpdateShowreelInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  bannerDate?: InputMaybe<Scalars["String"]["input"]>;
  bannerNumberOfVideos?: InputMaybe<Scalars["Int"]["input"]>;
  bannerType?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<ShowreelContentType>;
  feedbackProvided?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  languageBlacklist?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameType?: InputMaybe<Scalars["String"]["input"]>;
  variantBlacklist?: InputMaybe<Array<InputMaybe<ShowreelVariantInput>>>;
  videos?: InputMaybe<Array<InputMaybe<ShowreelVideoInput>>>;
};

export type UpdateTaskInput = {
  projectID: Scalars["ID"]["input"];
  taskID: Scalars["ID"]["input"];
  videoIDs: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type UpdateVersionVisibilityInput = {
  videoIDs: Array<Scalars["ID"]["input"]>;
};

export type UpdateVideoVariantInput = {
  id: Scalars["ID"]["input"];
  status?: InputMaybe<VariantStatus>;
};

export type User = {
  __typename: "User";
  auth0ID: Scalars["String"]["output"];
  disableAnalytics: Scalars["Boolean"]["output"];
  firstName: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  image: Maybe<Scalars["String"]["output"]>;
  /** @deprecated no longer used on FE */
  lastName: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  orgUnit: UserOrgUnit;
  type: UserType;
};

export enum UserOrgUnit {
  ClientSolutionsManager = "client_solutions_manager",
  CreativeLead = "creative_lead",
  Unassigned = "unassigned",
}

export enum UserType {
  Client = "CLIENT",
  Staff = "STAFF",
}

export type Variant = {
  __typename: "Variant";
  adUnit: Scalars["String"]["output"];
  aspectRatio: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  hash: Scalars["String"]["output"];
  hidden: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  language: Maybe<Scalars["String"]["output"]>;
  sequenceID: Maybe<Scalars["String"]["output"]>;
  sequenceOrder: Scalars["Int"]["output"];
  thumbnailURI: Scalars["String"]["output"];
  transcodeStatus: TranscodeStatus;
  /** @deprecated no longer need but still on FE */
  transcoded: Scalars["Boolean"]["output"];
  type: MediaType;
  versions: Maybe<Array<Maybe<VariantVersion>>>;
  videoURI: Scalars["String"]["output"];
  videoURIHDR: Maybe<Scalars["String"]["output"]>;
};

export type VariantFeedback = {
  __typename: "VariantFeedback";
  comments: Maybe<Array<Maybe<FeedbackComment>>>;
  hash: Scalars["ID"]["output"];
  noFeedback: Scalars["Boolean"]["output"];
  videoID: Scalars["Int"]["output"];
};

export type VariantFeedbackInput = {
  hash: Scalars["ID"]["input"];
  noFeedback: Scalars["Boolean"]["input"];
  showreelID: Scalars["ID"]["input"];
  token: Scalars["String"]["input"];
  videoID: Scalars["Int"]["input"];
};

export type VariantFromSuccessfulUploadInput = {
  briefID: Scalars["ID"]["input"];
  key: Scalars["String"]["input"];
  originalFileName: Scalars["String"]["input"];
  videoID: Scalars["ID"]["input"];
};

export type VariantRequestUploadUri = {
  __typename: "VariantRequestUploadURI";
  URI: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
};

export enum VariantStatus {
  Approved = "approved",
  Pending = "pending",
  Unapproved = "unapproved",
}

export type VariantVersion = {
  __typename: "VariantVersion";
  assets: Array<Asset>;
  clientVisible: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  isOriginalCreator: Scalars["Boolean"]["output"];
  uploadedBy: Scalars["ID"]["output"];
  uploadedDate: Scalars["Time"]["output"];
  versionOrder: Scalars["Int"]["output"];
  videoURI: Scalars["String"]["output"];
  videoURIHDR: Maybe<Scalars["String"]["output"]>;
};

export type VariantVersionVisibilityInput = {
  hidden: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type Video = {
  __typename: "Video";
  action: Maybe<Scalars["String"]["output"]>;
  allowlistedVariants: Maybe<Array<Maybe<Variant>>>;
  concept: Maybe<Scalars["String"]["output"]>;
  contentGroup: Maybe<ContentGroup>;
  /** @deprecated no longer used on FE */
  createdAt: Scalars["Time"]["output"];
  creativeConcept: Maybe<CreativeConcept>;
  creatorID: Scalars["ID"]["output"];
  creatorName: Scalars["String"]["output"];
  gifURI: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  internal: Scalars["Boolean"]["output"];
  latestVersionVisible: Scalars["Boolean"]["output"];
  notes: Maybe<VideoNotes>;
  order: Scalars["Int"]["output"];
  originalFilename: Scalars["String"]["output"];
  ownershipChanged: Scalars["Boolean"]["output"];
  preview: Maybe<Scalars["String"]["output"]>;
  rating: Maybe<VideoRating>;
  readOnly: Maybe<Scalars["Boolean"]["output"]>;
  selectedStatus: Maybe<SelectedStatus>;
  shortHash: Scalars["String"]["output"];
  showreelVideoID: Maybe<Scalars["String"]["output"]>;
  status: Maybe<Scalars["String"]["output"]>;
  thumbnail: Maybe<Scalars["String"]["output"]>;
  thumbnailURI: Scalars["String"]["output"];
  variants: Maybe<Array<Maybe<Variant>>>;
  videoVariants: Array<VideoVariant>;
  visible: Scalars["Boolean"]["output"];
  /** @deprecated no longer used on FE */
  whitelistedVariants: Maybe<Array<Maybe<Variant>>>;
};

export type VideoVariantsArgs = {
  includeHidden?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VideoAssignedCreatorInput = {
  briefID: Scalars["ID"]["input"];
  creatorID: Scalars["ID"]["input"];
  videoIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type VideoBatchDownload = {
  __typename: "VideoBatchDownload";
  currentFile: Maybe<Scalars["Int"]["output"]>;
  files: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  status: Maybe<VideoBatchDownloadStatus>;
  uri: Maybe<Scalars["String"]["output"]>;
};

export enum VideoBatchDownloadStatus {
  Processing = "processing",
  Ready = "ready",
}

export type VideoContentGroupInput = {
  contentGroupID?: InputMaybe<Scalars["ID"]["input"]>;
  videoID: Scalars["ID"]["input"];
};

export type VideoCreativeConceptInput = {
  creativeConceptID?: InputMaybe<Scalars["ID"]["input"]>;
  videoID: Scalars["ID"]["input"];
};

export enum VideoFileType {
  Mov = "mov",
  Mp4 = "mp4",
}

export type VideoInput = {
  briefID: Scalars["ID"]["input"];
};

export type VideoNotes = {
  __typename: "VideoNotes";
  approval: Maybe<Scalars["String"]["output"]>;
  internal: Maybe<Scalars["String"]["output"]>;
  showreel: Maybe<Scalars["String"]["output"]>;
};

export type VideoNotesInput = {
  id: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export enum VideoRating {
  Bad = "bad",
  Good = "good",
  Great = "great",
}

export type VideoRatingInput = {
  id: Scalars["ID"]["input"];
  videoRating: VideoRating;
};

export type VideoTagsInput = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  concept?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideoTaxonomies = {
  __typename: "VideoTaxonomies";
  brand: Taxonomy;
  briefType: Taxonomy;
  clientApproved: Taxonomy;
  durationRange: Taxonomy;
  framework: Taxonomy;
  industry: Taxonomy;
  misc: Taxonomy;
  origin: Taxonomy;
  placement: Taxonomy;
  videoRating: Taxonomy;
  visualTechnique: Taxonomy;
};

export type VideoVariant = {
  __typename: "VideoVariant";
  actioned: Scalars["Boolean"]["output"];
  adUnit: Scalars["String"]["output"];
  aspectRatio: Scalars["String"]["output"];
  assetType: AssetType;
  clientVisible: Scalars["Boolean"]["output"];
  feedback: Array<Feedback>;
  hdrURL: Maybe<Scalars["String"]["output"]>;
  hidden: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  latestVersionVisible: Scalars["Boolean"]["output"];
  preview: Scalars["String"]["output"];
  status: VariantStatus;
  thumbnail: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  versions: Array<VariantVersion>;
};

export type VideoVariantFeedbackArgs = {
  filter?: InputMaybe<FeedbackFilter>;
};

export type VideoVariantVisibilityInput = {
  hidden: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type VideoVariantsAdUnitAndLanguageInput = {
  adUnit?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  sequence: Scalars["Boolean"]["input"];
  variantIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type VideoVisibilityInput = {
  projectID: Scalars["ID"]["input"];
  taskID: Scalars["ID"]["input"];
  videoIDs: Array<InputMaybe<Scalars["ID"]["input"]>>;
  visible: Scalars["Boolean"]["input"];
};

export type VideosOrderInput = {
  videoIDs: Array<Scalars["ID"]["input"]>;
};

export type ZipStatus = {
  __typename: "ZipStatus";
  createdAt: Scalars["Time"]["output"];
  currentFile: Scalars["Int"]["output"];
  hash: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status: ZipStatusStatus;
  totalFiles: Scalars["Int"]["output"];
  updatedAt: Scalars["Time"]["output"];
  uri: Scalars["String"]["output"];
};

export enum ZipStatusStatus {
  Done = "done",
  Failed = "failed",
  Pending = "pending",
  Processing = "processing",
}

export type BrandContactFieldsFragment = {
  __typename: "Brand";
  contacts: Array<{
    __typename: "Contact";
    email: string;
    displayName: string;
    id: string;
    jobTitle: string;
  } | null> | null;
};

export type BrandFieldsFragment = {
  __typename: "Brand";
  id: string;
  imageURI: string;
  name: string;
};

export type TaxonFieldsFragment = {
  __typename: "Taxon";
  id: string;
  count: number | null;
  label: string;
};

export type TaxonomyFieldsFragment = {
  __typename: "Taxonomy";
  id: string;
  values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
};

export type VideoTaxonomiesFieldsFragment = {
  __typename: "Taxonomies";
  videos: {
    __typename: "VideoTaxonomies";
    brand: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    briefType: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    durationRange: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    framework: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    industry: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    misc: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    placement: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
    visualTechnique: {
      __typename: "Taxonomy";
      id: string;
      values: Array<({ __typename: "Taxon" } & TaxonFieldsFragment) | null>;
    };
  } | null;
};

export type BannerFieldsFragment = {
  __typename: "Showreel";
  bannerDate: any | null;
  bannerNumberOfVideos: number | null;
  bannerType: string | null;
};

export type ShowreelAuthedVideoFieldsFragment = {
  __typename: "Video";
  showreelVideoID: string | null;
  allVariants: Array<
    ({ __typename: "Variant" } & VariantFieldsFragment) | null
  > | null;
  variants: Array<
    ({ __typename: "Variant" } & VariantFieldsFragment) | null
  > | null;
} & UnauthedVideoFieldsFragment &
  VideoLabelsFragment;

export type VariantBlacklistFieldsFragment = {
  __typename: "ShowreelVariant";
  adUnit: string;
  aspectRatio: string;
};

export type ShowreelBriefFieldsFragment = {
  __typename: "Brief";
  clientBriefName: string | null;
  clientBriefSummary: string | null;
  clientSelectionCriteria: string | null;
  title: string;
  contentGroups: Array<{ __typename: "ContentGroup"; id: string } | null>;
};

export type ShowreelAuthedFieldsFragment = {
  __typename: "Showreel";
  active: boolean;
  briefID: number;
  briefShortHash: string;
  contentType: ShowreelContentType | null;
  id: string;
  guid: string;
  languageBlacklist: Array<string | null> | null;
  name: string | null;
  nameType: string | null;
  type: ShowreelType;
  brand: { __typename: "Brand" } & BrandFieldsFragment;
  brief: { __typename: "Brief" } & ShowreelBriefFieldsFragment;
  variantBlacklist: Array<
    ({ __typename: "ShowreelVariant" } & VariantBlacklistFieldsFragment) | null
  > | null;
  videos: Array<
    ({ __typename: "Video" } & ShowreelAuthedVideoFieldsFragment) | null
  > | null;
} & BannerFieldsFragment;

export type ShowreelVideoFieldsFragment = {
  __typename: "Video";
  variants: Array<
    ({ __typename: "Variant" } & UnauthedVariantFieldsFragment) | null
  > | null;
} & UnauthedVideoFieldsFragment;

export type UserFieldsFragment = {
  __typename: "User";
  id: string;
  image: string | null;
  name: string;
  orgUnit: UserOrgUnit;
};

export type ViewerFieldsFragment = {
  __typename: "User";
  id: string;
  firstName: string | null;
  orgUnit: UserOrgUnit;
};

export type UnauthedVariantFieldsFragment = {
  __typename: "Variant";
  adUnit: string;
  aspectRatio: string;
  hash: string;
  id: string;
  language: string | null;
  sequenceOrder: number;
  thumbnailURI: string;
  type: MediaType;
  videoURI: string;
  videoURIHDR: string | null;
};

export type VariantFieldsFragment = {
  __typename: "Variant";
  filename: string;
  hidden: boolean;
  transcoded: boolean;
  type: MediaType;
} & UnauthedVariantFieldsFragment;

export type UnauthedVideoFieldsWithoutNotesFragment = {
  __typename: "Video";
  gifURI: string;
  id: string;
  shortHash: string;
  thumbnailURI: string;
  contentGroup: { __typename: "ContentGroup"; id: string; name: string } | null;
};

export type UnauthedVideoNotesFieldsFragment = {
  __typename: "VideoNotes";
  showreel: string | null;
};

export type UnauthedVideoFieldsFragment = {
  __typename: "Video";
  contentGroup: { __typename: "ContentGroup"; id: string; name: string } | null;
  notes:
    | ({ __typename: "VideoNotes" } & UnauthedVideoNotesFieldsFragment)
    | null;
} & UnauthedVideoFieldsWithoutNotesFragment;

export type VideoLabelsFragment = {
  __typename: "Video";
  action: string | null;
  concept: string | null;
  status: string | null;
};

export type VideoFieldsWithoutVariantsFragment = {
  __typename: "Video";
  creatorID: string;
  creatorName: string;
  originalFilename: string;
} & UnauthedVideoFieldsWithoutNotesFragment &
  VideoLabelsFragment;

export type VideoVariantVersionsFieldsFragment = {
  __typename: "VariantVersion";
  id: string;
  clientVisible: boolean;
  isOriginalCreator: boolean;
  uploadedBy: string;
  uploadedDate: any;
  versionOrder: number;
  videoURI: string;
  videoURIHDR: string | null;
};

export type VideoFieldsWithoutNotesFragment = {
  __typename: "Video";
  variants: Array<
    | ({
        __typename: "Variant";
        sequenceID?: string | null;
        versions?: Array<
          | ({
              __typename: "VariantVersion";
            } & VideoVariantVersionsFieldsFragment)
          | null
        > | null;
      } & VariantFieldsFragment)
    | null
  > | null;
} & VideoFieldsWithoutVariantsFragment;

export type VideoNotesFieldsFragment = {
  __typename: "VideoNotes";
  internal: string | null;
  showreel: string | null;
};

export type VideoFieldsFragment = {
  __typename: "Video";
  notes: ({ __typename: "VideoNotes" } & VideoNotesFieldsFragment) | null;
} & VideoFieldsWithoutNotesFragment;

export type InternalTaxonomiesQueryVariables = Exact<{
  input?: InputMaybe<SearchInput>;
}>;

export type InternalTaxonomiesQuery = {
  __typename: "Query";
  taxonomies: {
    __typename: "Taxonomies";
    language: { __typename: "Taxonomy" } & TaxonomyFieldsFragment;
  } & VideoTaxonomiesFieldsFragment;
};

export type ShowreelFieldsFragment = {
  __typename: "Showreel";
  active: boolean;
  briefID: number;
  contentType: ShowreelContentType | null;
  guid: string;
  id: string;
  type: ShowreelType;
  brand: { __typename: "Brand" } & BrandFieldsFragment;
  brief: { __typename: "Brief" } & ShowreelBriefFieldsFragment;
  videos: Array<
    ({ __typename: "Video" } & ShowreelVideoFieldsFragment) | null
  > | null;
} & BannerFieldsFragment;

export type GetShowreelQueryVariables = Exact<{
  guid: Scalars["ID"]["input"];
}>;

export type GetShowreelQuery = {
  __typename: "Query";
  showreelByGUID: { __typename: "Showreel" } & ShowreelFieldsFragment;
};

export type GetShowreelAuthedQueryVariables = Exact<{
  guid: Scalars["ID"]["input"];
}>;

export type GetShowreelAuthedQuery = {
  __typename: "Query";
  showreelByGUID: { __typename: "Showreel" } & ShowreelAuthedFieldsFragment;
};

export type CommentsByVideoIdQueryVariables = Exact<{
  videoID: Scalars["ID"]["input"];
}>;

export type CommentsByVideoIdQuery = {
  __typename: "Query";
  commentsByVideoID: Array<{
    __typename: "VariantFeedback";
    hash: string;
    videoID: number;
    comments: Array<{
      __typename: "FeedbackComment";
      id: string;
      parentID: string | null;
      text: string;
      timestamp: string | null;
      showreel: {
        __typename: "Showreel";
        feedbackDate: any | null;
        guid: string;
        id: string;
        name: string | null;
      };
    } | null> | null;
  }>;
};

export type GetViewerFieldsFragment = {
  __typename: "User";
  auth0ID: string;
  disableAnalytics: boolean;
} & UserFieldsFragment &
  ViewerFieldsFragment;

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename: "Query";
  viewer: { __typename: "User" } & GetViewerFieldsFragment;
};
