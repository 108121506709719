import { ReactNode, createContext, useEffect, useReducer, useRef } from "react";
import MODAL from "shared/constants/actions/modal";
import { IAction } from "shared/types/actions";

interface IModalDetails {
  component: ReactNode;
  id: string;
  priority?: boolean;
}

interface IContext {
  dispatch: React.Dispatch<IAction>;
  modals: IModalDetails[];
}

export const ModalContext = createContext<IContext>({
  dispatch: () => null,
  modals: [],
});

interface IProps {
  blurBody?: boolean;
  children: ReactNode;
}

export const blurBody = () => {
  document.body.style.position = "relative";
  document.body.style.overflow = "hidden";
  document.getElementById("root")!.style.filter = "blur(0.25rem)";
  document.getElementById("root")!.style.height = "100vh";
  document.getElementById("root")!.style.overflow = "hidden";
};

export const unblurBody = () => {
  document.body.style.removeProperty("position");
  document.body.style.removeProperty("overflow");
  document.getElementById("root")!.style.removeProperty("filter");
  document.getElementById("root")!.style.removeProperty("height");
  document.getElementById("root")!.style.removeProperty("overflow");
};

export const modalReducer = (state: IModalDetails[], action: IAction) => {
  switch (action.type) {
    case MODAL.ADD: {
      if (
        !state.some((modal: IModalDetails) => modal.id === action.payload.id)
      ) {
        return action.payload.priority
          ? [{ ...action.payload }, ...state]
          : [...state, { ...action.payload }];
      }
      break;
    }
    case MODAL.REMOVE: {
      return state.filter(
        (modal: IModalDetails) => modal.id !== action.payload.id,
      );
    }
    case MODAL.REMOVE_ALL:
      return [];
    default:
      break;
  }
  return state;
};

function ModalProvider({ blurBody: blurBackground = true, children }: IProps) {
  const [modalState, dispatch] = useReducer(modalReducer, []);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (blurBackground) {
      if (modalState.length) {
        blurBody();
      } else {
        unblurBody();
      }
    }
  }, [blurBackground, modalState.length]);

  return (
    <ModalContext.Provider value={{ dispatch, modals: modalState }}>
      {children}
      {!!modalState.length && modalState[0].component}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
