import { css } from "styled-components";

const shadows = css`
  --vui-shadow-small:
    0 0.25rem 0.25rem 0 rgba(var(--vui-color-mono-10), 0.1),
    0 0.0625rem 0.1875rem 0.0625rem rgba(var(--vui-color-mono-10), 0.15);
  --vui-shadow-medium:
    0 0.25rem 0.5rem 0.25rem rgba(var(--vui-color-mono-10), 0.15),
    0 0.125rem 0.25rem 0 rgba(var(--vui-color-mono-10), 0.3);
`;

export default shadows;
