export enum Application {
  Clients = "dashboard",
  Creators = "creators",
  Embeds = "embeds",
  Internal = "internal",
  Integrations = "integrations",
  Showreel = "showreel",
}

export default Application;
