import { Auth0DecodedHash, WebAuth } from "auth0-js";
import axios from "axios";
import { IAuthConfig } from "shared/types/services/Auth";
import camelCaseKeys from "shared/utils/camelCaseKeys";

export default class Auth {
  private auth: WebAuth;
  private config: IAuthConfig;

  constructor(config: IAuthConfig) {
    this.config = config;
    const { audience, clientID, domain, redirectUri } = this.config;

    this.auth = new WebAuth({
      audience,
      clientID,
      domain,
      redirectUri,
      responseType: "token id_token",
      scope: "openid profile email",
    });
  }

  // SSO or other external authentication
  public authorize(connection?: string, referrerUri?: string) {
    const { host, protocol } = window.location;
    const { audience, redirectUri } = this.config;

    const state = `${protocol}//${host}/login/callback?app=internal#${this.randomString(32)}`;
    const referrerPart = referrerUri ? `&referrerUri=${referrerUri}` : "";

    this.auth.authorize({
      audience,
      connection,
      domain: "login.vidsy.co",
      redirectUri,
      responseMode: "query",
      responseType: "code",
      scope: "openid email profile offline_access",
      state: btoa(state + referrerPart), // This is used to prevent CSRF attacks on the BE and to help them identify the request in terms of credentials on the BE and make sure they are redirecting to the correct place
    });
  }

  public authorizeCallback(hash: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return reject(err);
        }

        if (!authResult) {
          return reject();
        }

        resolve(authResult);
      });
    });
  }

  // Direct email/password authentication
  public authorizeWithCredentials(email: string, password: string) {
    const { audience, realm } = this.config;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.client.login(
        {
          audience,
          password,
          realm: realm || "",
          username: email,
          scope: "openid profile email",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public logout(returnPath?: string) {
    let returnTo = returnPath;

    if (returnTo === undefined) {
      const { host, protocol } = window.location;

      returnTo = `${protocol}//${host}/login`;
    }

    const { clientID } = this.config;
    this.auth.logout({
      clientID,
      returnTo,
    });
  }

  // send email with code to user
  public passwordlessSend(email: string) {
    const { audience } = this.config;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          authParams: {
            audience,
            scope: "openid profile email",
          },
          connection: "email",
          email,
          send: "code",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public passwordlessCallback(hash: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return reject(err);
        }

        if (!authResult) {
          return reject();
        }

        resolve(authResult);
      });
    });
  }

  /* eslint-disable @typescript-eslint/naming-convention */ // For Auth0 API
  public async passwordlessOtpLogin(
    email: string,
    otp: string,
  ): Promise<Auth0DecodedHash> {
    const { audience, clientID, domain } = this.config;
    const url = `https://${domain}/oauth/token`;

    const body = {
      audience,
      client_id: clientID,
      grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
      otp: otp,
      realm: "email",
      scope: "openid profile email offline_access",
      username: email,
    };

    try {
      const { data } = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const camelCasedData = camelCaseKeys(data);

      return camelCasedData as Auth0DecodedHash;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }

  public async refreshAccessToken(
    refreshToken: string,
  ): Promise<Auth0DecodedHash> {
    const url = `https://${this.config.domain}/oauth/token`;
    const body = {
      client_id: this.config.clientID,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: "openid profile email offline_access",
    };

    try {
      const { data } = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const camelCasedData = camelCaseKeys(data);

      return camelCasedData as Auth0DecodedHash;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }

  private randomString(length: number): string {
    const charset =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
    let result = "";
    let remainingLength = length;

    while (remainingLength > 0) {
      const bytes = new Uint8Array(16);
      const random = window.crypto.getRandomValues(bytes);

      random.forEach((index) => {
        if (remainingLength === 0) {
          return;
        }

        if (index < charset.length) {
          result += charset[index];
          remainingLength = remainingLength - 1;
        }
      });
    }

    return result;
  }
}
