import Application from "shared/types/application";
import Environment from "shared/types/environment";
import IHostnames from "shared/types/hostnames";

const HOSTNAMES: IHostnames = {
  [Application.Clients]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3002
    }`,
    [Environment.Live]: "dashboard.vidsy.co",
    [Environment.Staging]: "dashboard.staging.vidsy.co",
  },
  [Application.Creators]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3000
    }`,
    [Environment.Live]: "creator.vidsy.co",
    [Environment.Staging]: "creator.staging.vidsy.co",
  },
  [Application.Embeds]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3003
    }`,
    [Environment.Live]: "embeds.vidsy.co",
    [Environment.Staging]: "embeds.staging.vidsy.co",
  },
  [Application.Integrations]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3001
    }`,
    [Environment.Live]: "integrations.vidsy.co",
    [Environment.Staging]: "integrations.staging.vidsy.co",
  },
  [Application.Internal]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3000
    }`,
    [Environment.Live]: "app.vidsy.co",
    [Environment.Staging]: "app.staging.vidsy.co",
  },
  [Application.Showreel]: {
    [Environment.Development]: `localhost:${
      parseInt(process.env.PORT as string, 10) || 3001
    }`,
    [Environment.Live]: "showreel.vidsy.co",
    [Environment.Staging]: "showreel.staging.vidsy.co",
  },
};

export default HOSTNAMES;
