import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import colors from "./colors";
import shadows from "./shadows";
import shape from "./shape";
import spacing from "./spacing";
import state from "./state";
import typography from "./typography";

export default createGlobalStyle`
  /* CSS Reset */
  ${reset}

  :root {
    ${colors}
    ${shadows}
    ${shape}
    ${spacing}
    ${state}
    ${typography}
  }

  html {
    background-color: rgb(var(--vui-color-background));
    /* Box model */
    box-sizing: border-box;
    color: rgb(var(--vui-color-on-background));
    font-size: 16px;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: var(--vui-type-body-shorthand);
    letter-spacing: var(--vui-type-body-spacing);
  }

  h1 {
    font: var(--vui-type-title-large-shorthand);
    letter-spacing: var(--vui-type-title-large-spacing);
  }

  h2 {
    font: var(--vui-type-title-medium-shorthand);
    letter-spacing: var(--vui-type-title-medium-spacing);
  }

  h3 {
    font: var(--vui-type-title-small-shorthand);
    letter-spacing: var(--vui-type-title-small-spacing);
  }

  h4, h5, h6 {
    font: var(--vui-type-title-body-shorthand);
    letter-spacing: var(--vui-type-title-body-spacing);
  }

  a {
  text-decoration: none;

    &:not(:has(*:not(strong))) {
      color: rgb(var(--vui-color-palette-informative));

      &:hover {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-weight: var(--vui-type-weight-semibold);
  }

  em {
    font-style: italic;
  }

  small {
    font: var(--vui-type-label-small-shorthand);
    letter-spacing: var(--vui-type-label-small-spacing);
  }

  table {
    width: 100%;
  }
`;
